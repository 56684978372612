<template>
	<form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-grid">
		<Toast />

		<div class="grid p-fluid stickBar">
			<div class="col-12">
				<div class="card card-w-title" id="stickBar">
					<Menubar :model="nestedMenuitems">
						<template #end>
							<div class="flex justify-content-end flex-wrap card-container green-container">
								<div class="flex align-items-center justify-content-center font-bold text-gray-500 text-xl m-2">
								</div>
								<div class="flex align-items-center justify-content-center m-2">
									<Button class="p-button-rounded p-button-danger mr-0 mb-0" v-if="$router.options.history.state.back != null" icon="pi pi-times" @click="$router.go(-1)"/>
								</div>
							</div>
						</template>
					</Menubar>
				</div>
			</div>
		</div>

		<div class="grid">
			<div class="col-12">
				<div class="card">
					<EntityHeader label="SATIŞ PROJESİ" entityName="opportunity"></EntityHeader>
					<div class="grid">
						<div class="col-12">
							<div class="field p-fluid">
								<label for="baslik">Başlık <span style="color:red">*</span></label>
								<InputText id="baslik" type="text" v-model="mainData.baslik" />
								<span v-if="v$.mainData.baslik.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.baslik.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-6">
							<div class="field p-fluid">
								<EntityLookup id="potansiyelMusteri" v-model="mainData.potansiyelMusteriName" ref="entity_potansiyelmusteri"
									label="Potansiyel Müşteri" entityName="account" nameField="name" :state="true" :required="true" 
									@itemSelected="potansiyelMusteriSelected = $event" @itemCleared="potansiyelMusteriSelected = null"></EntityLookup>
								<span v-if="v$.mainData.potansiyelMusteriName.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.potansiyelMusteriName.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-6">
							<div class="field p-fluid">
								<EntityLookup id="ilgilikisi" v-model="mainData.ilgiliKisiName" ref="entity_ilgilikisi"
									label="İlgili Kişi" entityName="contact" nameField="fullname" secondNameField="bm_unvan" secondNameFieldType="picklist"  :state="true" :required="true" 
									parentFieldName="parentcustomerid" :parentId="potansiyelMusteriSelected"
									@itemSelected="ilgiliKisiSelected = $event" @itemCleared="ilgiliKisiSelected = null"></EntityLookup>
								<span v-if="v$.mainData.ilgiliKisiName.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.ilgiliKisiName.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-12">
							<div class="field p-fluid">
								<label for="firmaadi">Açıklama</label>
								<Textarea id="firmaadi" rows="2" :autoResize="false" v-model="mainData.aciklama" />
							</div>
						</div>
						<div class="col-6">
							<div class="field p-fluid">
								<label for="durum">Durum</label>
								<Dropdown id="durum" v-model="mainData.durum" :options="SM_Durum" optionLabel="Value" optionValue="AttributeValue" placeholder="Durum" :showClear="true" :optionDisabled="true" disabled />
							</div>
						</div>
						<div class="col-6">
							<div class="field p-fluid">
								<label for="marka">Ürün Marka <span style="color:red">*</span></label>
								<Dropdown id="marka" v-model="mainData.urunMarka" :options="SM_Marka" optionLabel="Value" optionValue="AttributeValue" placeholder="Ürün Marka" :showClear="true" :filter="true" />
								<span v-if="v$.mainData.urunMarka.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.urunMarka.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12">
				<div class="card">
					<h5>TAHMİN BİLGİLERİ</h5>
					<div class="grid">
						<div class="col-2">
							<div class="field p-fluid">
								<label for="kapanisTarihi">Kapanış Tarihi <span style="color:red">*</span></label>
								<Calendar showButtonBar id="kapanisTarihi" v-model="mainData.kapanisTarihi" autocomplete="off" :showIcon="true" />
								<span v-if="v$.mainData.kapanisTarihi.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.kapanisTarihi.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-2">
							<div class="field p-fluid">
								<EntityLookup id="parabirimi" v-model="mainData.paraBirimiName" ref="entity_parabirimi"
									label="Para Birimi" entityName="transactioncurrency" nameField="currencyname" :state="true" :required="true" 
									@itemSelected="paraBirimiSelected = $event" @itemCleared="paraBirimiSelected = null"></EntityLookup>
								<span v-if="v$.mainData.paraBirimiName.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.paraBirimiName.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-2">
							<div class="field p-fluid">
								<label for="tezgahAdedi">Tezgah Adedi <span style="color:red">*</span></label>
								<InputNumber id="tezgahAdedi" v-model="mainData.tezgahAdedi" mode="decimal" locale="tr-TR" :minFractionDigits="0" :min="0" :showButtons="true" />
								<span v-if="v$.mainData.tezgahAdedi.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.tezgahAdedi.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<CrmSubject ref="subject_tezgahtipi" label="Tezgah Tipi" @itemSelected="TezgahTipiSelect" :required="true" :disabled="isDisabled"/>
								<span v-if="v$.mainData.tezgahTipiId.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.tezgahTipiId.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="projeninDurumu">Projenin Durumu <span style="color:red">*</span></label>
								<Dropdown id="projeninDurumu" v-model="mainData.projeninDurumu" :options="SM_ProjeninDurumu" optionLabel="Value" optionValue="AttributeValue" placeholder="Projenin Durumu" :showClear="true" />
								<span v-if="v$.mainData.projeninDurumu.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.projeninDurumu.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12">
				<div class="card">
					<h5>TERCİHLER</h5>
					<div class="grid">
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="kayitSahibi" v-model="mainData.kayitSahibiName" ref="entity_kayitSahibi"
									label="Kayıt Sahibi" entityName="bm_teknikkullanicilar" nameField="bm_name" :state="true" :required="true" 
									@itemSelected="kayitSahibiSelected = $event" @itemCleared="kayitSahibiSelected = null"
									parentFieldName="bm_anadepartman" :parentId="{'Value': 1000}">
								</EntityLookup>
								<span v-if="v$.mainData.kayitSahibiName.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.kayitSahibiName.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</template>

<script>
import CrmService from "../service/CrmService";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";

export default {
	setup: () => ({ v$: useVuelidate() }),
	data() {
		return {
			crmService: null,
			submitted: false,
			OBJECT_TYPE_CODE: 3,
			mainData: {},

			nestedMenuitems: [
				{
					label:'Kaydet',
					icon:'pi pi-fw pi-save',
					command: () => { this.OnSave(); },
				},
			],
			tezgahTipiNodes: [],
		}
	},
	async created() {
		this.crmService = new CrmService();

		console.log('created');
		window.addEventListener('scroll', this.handleScroll);

		let loader = this.$loading.show({
			container: this.$refs.smsContainer
		});

        try {
			debugger;

			if (this.entityId != null) {
				try {
					debugger;
					this.crmService.GetEntityById({
					"entityName": "account",
					"entityId": this.entityId,
					"columnNames": ["name", "accountid"]})
					.then(data_account => {
						if (data_account.jsonData != null) {
							debugger;

							this.mainData["firmaId"] = data_account.jsonData["accountid"];
							this.mainData["firmaName"] = data_account.jsonData["name"];

							let accountItem = { "Value": data_account.jsonData["accountid"], "Name": data_account.jsonData["name"] };

							this.$refs.entity_potansiyelmusteri.setDefaultData(accountItem);
							this.potansiyelMusteriSelected = accountItem;
						}
					})
					.catch(error => console.log(error));

				} catch (error) {
					console.log(error);
					//this.$router.push('/');
				}
				finally {
					loader.hide();
				}
			}
			else {
				loader.hide();
			}
			
			this.mainData["tezgahAdedi"] = 1;

			setTimeout(() => {
				let defaultUserData = { "Value": this.profileData.ID, "Name": this.profileData.TamAdi };
				this.$refs.entity_kayitSahibi.setDefaultData(defaultUserData);
				this.kayitSahibiSelected = defaultUserData;

				let defaulParaBirimiData = { "Value": "4f6220a0-6aeb-dc11-a742-000e0c3f151f", "Name": "Euro" };
				this.$refs.entity_parabirimi.setDefaultData(defaulParaBirimiData);
				this.paraBirimiSelected = defaulParaBirimiData;


			}, 500);
			

			this.tezgahTipiNodes = await this.crmService.GetSubjects();
		} catch (error) {
			console.log(error);
			//this.$router.push('/');
		}
		finally {
			loader.hide();
		}
    },
	computed: {
		entityId() {
			return this.$route.params.id;
		},
		profileData(){
			return this.$store.getters.getProfile;
		},
		SM_Durum: function() {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'statecode');
		},
		SM_Marka: function() {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_urunmarka');
		},
		SM_ProjeninDurumu: function() {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_projenindurumu');
		},
		potansiyelMusteriSelected: {
			get: function () {
				if (this.mainData["potansiyelMusteriId"]) {
					return { "Value": this.mainData["potansiyelMusteriId"], "Name": this.mainData["potansiyelMusteriName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["potansiyelMusteriId"] = null;
					this.mainData["potansiyelMusteriName"] = null;
				}
				else {
					this.mainData["potansiyelMusteriId"] = newValue.Value;
					this.mainData["potansiyelMusteriName"] = newValue.Name;

					this.crmService.GetEntityById({
						"entityName": "account",
						"entityId": newValue.Value,
						"columnNames": ["transactioncurrencyid"]})
					.then(data => {
						if (data.jsonData != null) {
							debugger;

							this.$refs.entity_parabirimi.setDefaultData({ "Value": data.jsonData["transactioncurrencyid"], "Name": data.jsonData["transactioncurrencyidName"] });
							this.paraBirimiSelected = { "Value": data.jsonData["transactioncurrencyid"], "Name": data.jsonData["transactioncurrencyid"] };
						}
					})
					.catch(error => console.log(error));
				}
			}
		},
		ilgiliKisiSelected: {
			get: function () {
				if (this.mainData["ilgiliKisiId"]) {
					return { "Value": this.mainData["ilgiliKisiId"], "Name": this.mainData["ilgiliKisiName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["ilgiliKisiId"] = null;
					this.mainData["ilgiliKisiName"] = null;
				}
				else {
					this.mainData["ilgiliKisiId"] = newValue.Value;
					this.mainData["ilgiliKisiName"] = newValue.Name;
				}
			}
		},
		paraBirimiSelected: {
			get: function () {
				if (this.mainData["paraBirimiId"]) {
					return { "Value": this.mainData["paraBirimiId"], "Name": this.mainData["paraBirimiName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["paraBirimiId"] = null;
					this.mainData["paraBirimiName"] = null;
				}
				else {
					this.mainData["paraBirimiId"] = newValue.Value;
					this.mainData["paraBirimiName"] = newValue.Name;
				}
			}
		},
		kayitSahibiSelected: {
			get: function () {
				if (this.mainData["kayitSahibiId"]) {
					return { "Value": this.mainData["kayitSahibiId"], "Name": this.mainData["kayitSahibiName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.mainData["kayitSahibiId"] = null;
					this.mainData["kayitSahibiName"] = null;
				}
				else {
					this.mainData["kayitSahibiId"] = newValue.Value;
					this.mainData["kayitSahibiName"] = newValue.Name;
				}
			}
		},
	},
	methods: {
		async OnSave() {
			debugger;

			this.submitted = true;
			this.v$.$touch();

			if (this.v$.$invalid) {
				this.v$.$errors.forEach(element => {
					this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: element.$message, life: 3500});
				});
			} else {
				let loader = this.$loading.show({
					container: this.$refs.smsContainer
				});

				try {
					
					const response = await this.crmService.getFirsatCreate(this.mainData);
					debugger;
					if (response) {
						if (response.hata == true) {
							this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
						}else {
							this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Kaydedildi', life: 3000});
							
							if (window.opener) {
								window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
							}

							setTimeout(() => {
								this.$router.push({ name: 'opportunity', params: { id: response.olusturulanId } });
							}, 2000);
						}
					}
				} catch (error) {
					this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
				}
				finally {
					loader.hide();
				}
			}
		},
		TezgahTipiSelect(event) {
			this.mainData["tezgahTipiId"] = event;
		},
		formatNumber(value) {
            return parseInt(value).toLocaleString('tr-TR');
        },
		handleScroll() {
			const el = document.getElementById('stickBar');

			if (window.scrollY > 50) {
				el.style.boxShadow = '0px 2px 1px rgba(0, 0, 0, 0.09), 0px 4px 2px rgba(0, 0, 0, 0.09), 0px 8px 4px rgba(0, 0, 0, 0.09), 0px 16px 8px rgba(0, 0, 0, 0.09), 0px 32px 16px rgba(0, 0, 0, 0.09)';
			}
			else {
				el.style.boxShadow = '';
			}
		},
	},
	watch: {
		profileData(val) {
			if (val) {
				if (val.moduller) {
					const filtered = val.moduller.filter(x => x.name == 'Satış Projeleri');
					if (filtered.length == 0) {
						this.$router.replace({ name: 'accessdenied' });
					}
				}
			}
		}
	},
	validations() {
		return {
			mainData: {
				baslik: {
					required : helpers.withMessage('Başlık alanı dolu olmalıdır!', required),
				},
				potansiyelMusteriName: {
					required : helpers.withMessage('Potansiyel Müşteri alanı dolu olmalıdır!', required),
				},
				ilgiliKisiName: {
					required : helpers.withMessage('İlgili Kişi alanı dolu olmalıdır!', required),
				},
				urunMarka: {
					required : helpers.withMessage('Ürün Marka alanı dolu olmalıdır!', required),
				},
				kapanisTarihi: {
					required : helpers.withMessage('Kapanış Tarihi alanı dolu olmalıdır!', required),
				},
				paraBirimiName: {
					required : helpers.withMessage('Para Birimi alanı dolu olmalıdır!', required),
				},
				tezgahAdedi: {
					required : helpers.withMessage('Tezgah Adedi alanı dolu olmalıdır!', required),
				},
				tezgahTipiId: {
					required : helpers.withMessage('Tezgah Tipi alanı dolu olmalıdır!', required),
				},
				projeninDurumu: {
					required : helpers.withMessage('Projenin Durumu alanı dolu olmalıdır!', required),
				},
				kayitSahibiName: {
					required : helpers.withMessage('Kayıt Sahibi alanı dolu olmalıdır!', required),
				},
			},
		}
	}
}
</script>

<style lang="scss" scoped>

</style>
